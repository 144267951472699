<template>
  <img alt="Design" src="../assets/images/JAAvatar.webp">
</template>

<script>
export default {
  name: "Logo"
}
</script>

<style scoped>
  img {
    max-width: 150px;
    margin-bottom: 30px;
  }
</style>