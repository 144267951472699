export default {
    repoData: {
        selectedIndex: 0,
        list: [
            // {
            //     title: 'expression-evaluator',
            //     meta: {
            //         language: 'v',
            //         description: 'A simple expression evaluator.',
            //         stars: 2,
            //         forks: 7,
            //     },
            // },
            // {
            //     title: 'wp-db-merge',
            //     meta: {
            //         language: 'php',
            //         description: 'WordPress: Merge a non-multi site database into a multi site database',
            //         stars: 3,
            //         forks: 9,
            //     },
            // },
            // {
            //     title: 'expression-evaluator',
            //     meta: {
            //         language: 'v',
            //         description: '',
            //         stars: 2,
            //         forks: 7,
            //     },
            // },
            // {
            //     title: 'expression-evaluator',
            //     meta: {
            //         language: 'v',
            //         description: '',
            //         stars: 2,
            //         forks: 7,
            //     },
            // },
            // {
            //     title: 'expression-evaluator',
            //     meta: {
            //         language: 'v',
            //         description: '',
            //         stars: 2,
            //         forks: 7,
            //     },
            // },
            // {
            //     title: 'expression-evaluator',
            //     meta: {
            //         language: 'v',
            //         description: '',
            //         stars: 2,
            //         forks: 7,
            //     },
            // },
        ]
    }
}